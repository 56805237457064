import Vue from 'vue'

const components = {
  Logo: () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => c.default || c),
  CommonModal: () => import('../../components/common/modal.vue' /* webpackChunkName: "components/common-modal" */).then(c => c.default || c),
  SkillsDivorceCoach: () => import('../../components/skills/divorce-coach.vue' /* webpackChunkName: "components/skills-divorce-coach" */).then(c => c.default || c),
  SkillsLifeCoach: () => import('../../components/skills/life-coach.vue' /* webpackChunkName: "components/skills-life-coach" */).then(c => c.default || c)
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
